import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LogoutButton from "../../components/LogoutButton";
import useFetch from "../../hooks/useFetch";
import { API_BASEURL } from "../../constants/apis";
import LoadingComp from "../../components/LoadingComp";
import ErrorComp from "../../components/ErrorComp";
import BackButton from "../../components/BackButton";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function AmaLayout() {
  const { data, error, loading } = useFetch(`${API_BASEURL}/ama/v2/account/me`);
  const navigate = useNavigate();

  if (loading) {
    return <LoadingComp />;
  } else if (error) {
    return <ErrorComp error={error} />;
  }
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-gray-600 text-white">
        <div className="min-h-screen pb-16 ">
          <div className="flex w-full justify-between sticky top-0 bg-gray-600  pt-4 pb-2 z-50">
            <BackButton />
            <div
              onClick={() => navigate("/profile")}
              className="flex w-full justify-end hover:cursor-pointer"
            >
              <div className="flex flex-col items-end mr-2">
                <div className="text-sm"> {data?.user?.firstName} </div>
                <div className="text-xs"> {data?.user?.email} </div>
              </div>
              <div className=" w-8 h-8 mr-5 text-white flex justify-center items-center text-center bg-gray-300 rounded-full">
                {data?.user?.firstName?.substring(0, 1)}
              </div>
            </div>
          </div>
          <Outlet />
        </div>
        {/* <LogoutButton /> */}
      </div>
    </ThemeProvider>
  );
}

export default AmaLayout;
