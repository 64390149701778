import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { List, Tooltip } from "@mui/material";
import SourceIcon from "@mui/icons-material/SourceOutlined";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL } from "../../../constants/apis";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";
import AddFolder from "./AddFolder";

import {
  ChevronRight,
  Grid3x3,
  ViewDay,
  ViewModule,
} from "@mui/icons-material";
import SharedMails from "./SharedMails";
import SyncButton from "../../../components/syncButton";
import LabelsCard from "../../../components/LabelsCard";

function SharedFolder() {
  const { emailId } = useParams();
  const navigate = useNavigate();
  const [mailView, setMailView] = useState(false);

  const { data, loading, error } = useFetch(
    `${API_BASEURL}/api/v2/folder/${emailId}`
  );

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }

  if (data && data.folders.length < 1) {
    return (
      <div>
        <div className="px-5 pt-5 text-center font-semibold text-xs">
          WE NEED TO KNOW WHICH FOLDER DID YOU WANT US TO ACCESS WITH
        </div>
        <AddFolder />
      </div>
    );
  }

  return (
    <div>
      <div className="flex w-full justify-end ">
        <Tooltip enterDelay={700} title="Sync mail click once ">
          <SyncButton />
        </Tooltip>
        <Tooltip enterDelay={700} title="Switch View ">
          <button
            className="flex hover:bg-gray-100 text-zinc-600 rounded-md px-2 py-2 space-x-2 duration-300 text-sm items-center"
            onClick={() => setMailView(!mailView)}
          >
            {mailView ? <ViewModule /> : <ViewDay />}
            {mailView ? "Label view" : "Mail view"}
          </button>
        </Tooltip>
      </div>
      {mailView ? (
        <SharedMails />
      ) : (
        <nav aria-label="main mailbox folders " className="min-h-[60vh]">
          <List>
            {data &&
              data.folders &&
              data.folders.map((fold, index) => (
                <LabelsCard data={fold} key={fold.id} />
              ))}
          </List>
        </nav>
      )}

      <div
        onClick={() => {
          navigate(`/user/emailid/${emailId}/addfolder`);
        }}
        className="mt-10 sticky bottom-0 text-white text-center font-bold rounded-md border border-blue-700 py-2 bg-blue-600 hover:bg-blue-500 cursor-pointer duration-300 hover:shadow-md"
      >
        SHARE MORE
      </div>
    </div>
  );
}

export default SharedFolder;
