export const API_BASEURL = "https://api.ignitespring.io";
export const WEB_URL = "https://testaddin.ignitespring.io";

// export const API_BASEURL = "http://localhost:4000";
// export const WEB_URL = "http://localhost:3000";

export const TOKEN_KEY = "_SAUT";
export const ROLE_KEY = "_SROLE";
export const SELECTEDID_KEY = "_MID";

export const MESSAGEID = "messageId";
