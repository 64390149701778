import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Eye24Regular } from "@fluentui/react-icons";
import moment from "moment";
import EmailMarkOption from "./EmailMarkOption";
import { officeDialogHandler } from "../utils/officeDialog";
import ViewResponseButton from "./ViewResponseButton";
import { ROLE_KEY, WEB_URL } from "../constants/apis";

function EmailCard({ data, tab = "SENT", options = true }) {
  const [isUnRead, setIsUnRead] = useState(data.islabels.includes("VAUNREAD"));

  return (
    <div
      className={`${
        localStorage.getItem(ROLE_KEY) === "user" &&
        data.labels.includes("UNREAD") &&
        "font-bold"
      } w-[20rem] flex items-center justify-center mt-4  mx-auto  ${
        localStorage.getItem(ROLE_KEY) === "ama" &&
        data.islabels.includes("VAUNREAD") &&
        isUnRead &&
        "font-bold"
      }  `}
    >
      <div
        className={`border rounded-md overflow-hidden  border-gray-300/50  hover:shadow-md duration-300 ${
          (data?.followUpScheduled || data?.reminderScheduled) &&
          "bg-yellow-500/50"
        } `}
      >
        <div className="text-xs pl-5 w-full text-center">
          {data?.followUpScheduled &&
            `Follow up,  Start  ${moment(
              data?.followupId?.startDateTime
            ).calendar()}`}
        </div>
        <div
          onClick={() => {
            officeDialogHandler(
              `${WEB_URL}/email/view/${data._id}/${localStorage.getItem(
                ROLE_KEY
              )}`
            );
          }}
          className="w-[20rem]"
        >
          <div className="hover:bg-zinc-300/10 cursor-pointer py-2 px-3 duration-300">
            <div className="flex justify-between">
              <div>
                <div className="line-clamp-1 text-md font-medium">
                  {data.fromName.replace(/<.*?>/g, "")}
                </div>
                <div className="line-clamp-1 text-sm">
                  {data.subject
                    .replace(/<[^>]*>/g, "")
                    .replace()
                    .substring(0, 40)}
                </div>
              </div>
              <div className="text-center  text-[10px]">
                {`${moment(data.createdDateTime).format("MMM DD/YY hh:mm a")}`}
              </div>
            </div>
            <div className="flex line-clamp-1">
              <div className="text-xs opacity-60 ">
                {data.bodyPreview.replace(/<[^>]*>/g, "").replace()}
              </div>
            </div>
          </div>
        </div>
        <div className=" px-2 py-1 flex justify-between ">
          {options ? <EmailMarkOption data={data} /> : <div> </div>}
          <div className="flex">
            <Tooltip enterDelay={700} title="View full mail">
              <IconButton
                onClick={() => {
                  setIsUnRead(false);
                  window.location.href = `${WEB_URL}/email/view/${
                    data._id
                  }/${localStorage.getItem(ROLE_KEY)}`;
                }}
              >
                <Eye24Regular />
              </IconButton>
            </Tooltip>
            {tab === "SENT" ? (
              <></>
            ) : tab === "DRAFT" || tab === "CLIENT" ? (
              <ViewResponseButton data={data} />
            ) : tab === "PENDING" ? (
              <ViewResponseButton data={data} create={true} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailCard;
