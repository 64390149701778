import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL } from "../../../constants/apis";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";
import PushMailCard from "../../../components/PushMailCard";

function PushMails() {
  const { emailId } = useParams();

  const { data, error, loading } = useFetch(
    API_BASEURL + `/ama/v2/pushmail/email/${emailId}`
  );
  if (loading) {
    return <LoadingComp />;
  }
  if (error) {
    return <ErrorComp error={error} />;
  }
  if (!data || !data.pushMails || (data && data.pushMails.length < 1)) {
    return (
      <div className="h-[70vh] flex justify-center items-center">
        <div className="flex justify-center items-center h-full">
          No push mails currently
        </div>
      </div>
    );
  }
  return (
    <div>
      {data && data.pushMails.map((each) => <PushMailCard each={each} />)}
    </div>
  );
}

export default PushMails;
