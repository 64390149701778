import React from "react";

function BuilderTemplate() {
  return (
    <div className="min-h-screen">
      <div className="flex flex-col w-full  justify-center items-center">
        <div className="bg-gray-100 mx-3 max-w-md w-full h-screen flex flex-col items-center justify-center space-y-5">
          <div className="font-bold text-xl border-b p-3">
            Choose template you would like to use
          </div>
          <a
            href="/builder/fast"
            className="bg-white border rounded-md hover:shadow-md w-full max-w-xs flex flex-col duration-300 py-3 px-2 cursor-pointer"
          >
            Fast follow-up
            <div className="text-xs text-gray-500">
              It will have presetted template for every week followup
            </div>
          </a>
          <a
            href="/builder/slow"
            className="bg-white border rounded-md hover:shadow-md w-full max-w-xs flex flex-col duration-300 py-3 px-2 cursor-pointer"
          >
            Slow follow-up
            <div className="text-xs text-gray-500">
              It will have presetted template for every 3 month followup
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default BuilderTemplate;
