import { Route, Routes } from "react-router-dom";
import Landing from "../pages/Landing";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import HomeLayout from "../layout/user/HomeLayout";
import AmaLayout from "../layout/ama/AmaLayout";
import AmaHome from "../pages/ama/AmaHome";
import UserHome from "../pages/user/UserHome";
import AddFolder from "../pages/user/sharedfolder/AddFolder";
import AddMailAddress from "../pages/user/AddMailAddress";
import EmailIdLayout from "../layout/user/EmailIdLayout";
import EmailByFolder from "../pages/user/sharedfolder/EmailByFolder";
import AMAEmailByFolder from "../pages/ama/sharedfolder/EmailByFolder";
import ResponsesList from "../pages/user/response/ReponsesList";
import AMAResponsesList from "../pages/ama/response/ReponsesList";
import AmaEmailLayout from "../layout/ama/AmaEmailLayout";
import AmaEmailIdLayout from "../layout/ama/AmaEmailIdLayout";
import EmailView from "../pages/EmailView";
import ProtectedRoute from "../routes/ProtectedRoute";
import UnprotectedRoute from "../routes/UnprotectedRoute";
import EmailByIdHomeLayout from "../pages/user/EmailByIdHome";
import CreatePushmail from "../pages/ama/pushmails/CreatePushmail";
import PageNotFound from "../pages/404";
import CreateResponse from "../pages/ama/response/CreateResponse";
import ComposePage from "../pages/user/ComposeMail";
import GetMatch from "../pages/ama/deals/GetMatch";
import ProfilePage from "../pages/ProfilePage";
import SuccessPage from "../pages/SuccessPage";
import SharedDonePage from "../pages/SharedDonePage";
import CompanySearch from "../pages/user/Profile/CompanySearch";
import Builder from "../pages/Builder";
import BuilderTemplate from "../pages/BuilderTemplate";
import FastBuilder from "../pages/FastBuilder";
import ComposeFollowup from "../pages/user/ComposeFollowup/compose_followpup";

function RoutesList() {
  return (
    <Routes>
      <Route path="shared" element={<SharedDonePage />} />
      <Route path="success" element={<SuccessPage />} />
      <Route path="compose" element={<ComposePage />} />
      <Route path="email/view/:id/:role" element={<EmailView />} />
      <Route path="builder" element={<BuilderTemplate />} />
      <Route path="builder/fast" element={<FastBuilder />} />
      <Route path="builder/slow" element={<Builder />} />

      <Route element={<UnprotectedRoute />}>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="/user" element={<HomeLayout />}>
          <Route path="" index element={<UserHome />} />
          <Route path="addmail" element={<AddMailAddress />} />

          <Route path="emailid/:emailId" element={<EmailIdLayout />}>
            <Route path="" element={<EmailByIdHomeLayout />} />
            <Route path="emails/:folderId" element={<EmailByFolder />} />
            <Route path="response/:email_id" element={<ResponsesList />} />
            <Route path="followup/:email_id" element={<ComposeFollowup />} />
            <Route path="addfolder" element={<AddFolder />} />
          </Route>
        </Route>
        <Route path="/ama" element={<AmaLayout />}>
          <Route path="" index element={<AmaHome />} />
          <Route path="emailid/:emailId" element={<AmaEmailIdLayout />}>
            <Route path="" index element={<AmaEmailLayout />} />
            <Route path="emails/:folderId" element={<AMAEmailByFolder />} />
            <Route path="addfolder" element={<AddFolder />} />
            <Route path="response/:email_id" element={<AMAResponsesList />} />
            <Route path="create-pushmail" element={<CreatePushmail />} />
            <Route
              path="create-response/:email_id"
              element={<CreateResponse />}
            />
            <Route path="getmatch" element={<GetMatch />} />
          </Route>
        </Route>
        <Route path="profile" element={<ProfilePage />} />
        <Route path="company" element={<CompanySearch />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RoutesList;
