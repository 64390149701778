import React, { useEffect, useState } from "react";
import BackButton from "../components/BackButton";
import useFetch from "../hooks/useFetch";
import { API_BASEURL } from "../constants/apis";
import LoadingComp from "../components/LoadingComp";
import ErrorComp from "../components/ErrorComp";
import LogoutButton from "../components/LogoutButton";
import { Link } from "react-router-dom";

function ProfilePage() {
  const { data, loading, error } = useFetch(API_BASEURL + "/api/v2/account");
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    setUserInfo(data);
  }, [data]);

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }
  return (
    <div>
      <BackButton />

      <div className="flex flex-col min-h-[70vh] px-4">
        <div className=" flex flex-col justify-center items-center">
          <div className="w-10 h-10 bg-gray-200 rounded-full" />
          <div className="text-xl font-bold">
            {data && data.user.firstName.toUpperCase()}
          </div>
        </div>
        <div className="pt-10  px-2 text-sm mb-2 text-blue-700 font-bold">
          Manage Account
        </div>
        <div className=" border rounded-md ">
          <button
            onClick={() => {
              window.open("https://business.ignitespring.io", "_blank");
            }}
            className="py-3 px-4 border-b hover:rounded-md hover:shadow-md cursor-pointer duration-300 text-start w-full"
          >
            Profile update
          </button>
          <button
            onClick={() => {
              window.open("https://business.ignitespring.io", "_blank");
            }}
            className="py-3 px-4 border-b hover:rounded-md hover:shadow-md cursor-pointer duration-300 text-start w-full"
          >
            Manage shared data
          </button>
          <button
            onClick={() => {
              window.open("https://business.ignitespring.io", "_blank");
            }}
            className="py-3 px-4 border-b hover:rounded-md hover:shadow-md cursor-pointer duration-300 text-start w-full"
          >
            Security setting
          </button>
        </div>
        <div className="pt-10  px-2 text-sm mb-2 text-blue-700 font-bold">
          Client Overview
        </div>
        <div className=" border rounded-md ">
          <button
            onClick={() => {
              window.open("https://business.ignitespring.io", "_blank");
            }}
            className="py-3 px-4 border-b hover:rounded-md hover:shadow-md cursor-pointer duration-300 text-start w-full"
          >
            Security setting
          </button>
        </div>
      </div>

      <LogoutButton />
    </div>
  );
}

export default ProfilePage;
