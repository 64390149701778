import React, { createContext, useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { API_BASEURL, ROLE_KEY, TOKEN_KEY } from "../../constants/apis";
import { useParams } from "react-router-dom";
import axios from "axios";

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [alertLoading, setAlertLoading] = useState(false);
  const [detailAlert, setDetailAlert] = useState();

  useEffect(() => {
    fetchAlertData();
  }, []);

  const fetchAlertData = () => {
    setAlertLoading(true);
    const source = axios.CancelToken.source();

    axios
      .get(`${API_BASEURL}/api/v2/alert/ama`, {
        cancelToken: source.token,

        headers: {
          Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
        },
      })
      .then((res) => {
        setAlerts(res.data.alerts);
        console.log(res.data.alerts);
      })
      .catch((err) => {
        console.log(err.message);
        console.log("ALERT FETCH ERROR");
      })
      .finally(() => {
        setAlertLoading(false);
      });
  };

  const fetchAlertDetail = (emailId) => {
    setAlertLoading(true);
    const source = axios.CancelToken.source();

    axios
      .get(`${API_BASEURL}/api/v2/alert/detail/${emailId}`, {
        cancelToken: source.token,

        headers: {
          Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
        },
      })
      .then((res) => {
        setDetailAlert(res.data.alerts);
        setUserRole(res.data.role);
      })
      .catch((err) => {
        console.log(err.message);
        console.log("ALERT FETCH ERROR");
      })
      .finally(() => {
        setAlertLoading(false);
        fetchAlertData();
      });
  };

  return (
    <AlertContext.Provider
      value={{
        alerts,
        alertLoading,
        fetchAlertData,
        fetchAlertDetail,
        detailAlert,
        userRole,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;

export { AlertContext, AlertProvider };
