import { Telegram } from "@mui/icons-material";
import { Button, Fab, IconButton } from "@mui/material";
import React, { useState } from "react";

function GetMatch() {
  const [match, setMatches] = useState([
    { name: "Addrocketery", about: "About the company" },
    { name: "Fluent", about: "About the company" },
  ]);

  return (
    <div>
      <div className="text-center w-full flex flex-col items-center font-bold text-xl">
        Select matching
      </div>
      <div className="flex py-2 px-5  duration-300 items-center border-b border-gray-800 bg-gray-800">
        <div className=" w-10  ">Logo</div>
        <div className="ml-3 w-20">Name </div>
        <div className="ml-3 w-26">About </div>
      </div>
      {match.map((each, index) => (
        <div
          className="flex py-2 px-5 hover:bg-gray-700 duration-300 items-center border-b border-gray-800"
          key={index}
        >
          <div className="bg-gray-500 shadow-lg w-10 h-10 rounded-full" />
          <div className="ml-3 w-20 ">{each.name.substring(0, 8)} </div>
          <div className="ml-3 w-26">{each.about} </div>
          <div className="flex ml-5">
            <Telegram /> match
          </div>
        </div>
      ))}
      <div className="flex w-full justify-center">
        <Button>View full version</Button>
      </div>
    </div>
  );
}

export default GetMatch;
