import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../context/AlertContext";
import { Tooltip } from "@mui/material";
import { ROLE_KEY } from "../../constants/apis";

function UsersCard({ data }) {
  const navigate = useNavigate();

  const { alerts, alertLoading } = useContext(AlertContext);

  return (
    <div className={"items-center  mx-auto "}>
      <button
        onClick={() => {
          navigate(`/${localStorage.getItem(ROLE_KEY)}/emailid/${data._id} `);
        }}
        className="flex w-full items-center hover:bg-white/10 hover:shadow-md  duration-300 border-b border-gray-300/30 py-3 px-5 hover:rounded-md"
      >
        <div className="mr-3 py-2">
          <img
            src={data.picture}
            alt="google"
            className="w-12 h-12 rounded-full object-cover overflow-hidden"
          />
        </div>

        <div className="flex flex-col justify-start">
          <div className="text-start font-medium">{data.displayName}</div>
          <div className="text-start text-sm opacity-60">{data.emailId}</div>
        </div>

        {!alertLoading &&
          alerts.filter((each) => each.email === data._id).length > 0 && (
            <Tooltip enterDelay={900} title="Unread notification">
              <div className="w-5 h-5 bg-red-500/50 rounded-full absolute  right-10 text-sm">
                {alerts.filter((each) => each.email === data._id)[0]?.alert}
              </div>
            </Tooltip>
          )}
      </button>
    </div>
  );
}

export default UsersCard;
