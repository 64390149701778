import React from "react";
import BackButton from "../../components/BackButton";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_BASEURL } from "../../constants/apis";
import LoadingComp from "../../components/LoadingComp";
import ErrorComp from "../../components/ErrorComp";

function EmailIdLayout() {
  const { emailId } = useParams();
  const { loading, data, error } = useFetch(
    `${API_BASEURL}/api/v2/email/emailData/${emailId}`
  );

  const navigate = useNavigate();

  if (loading) {
    return <LoadingComp />;
  }
  if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div>
      <div className="sticky top-0 bg-white flex flex-col z-50 mp-20">
        <div className="flex w-full justify-between">
          <BackButton />
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/user");
            }}
            className="flex cursor-pointer"
          >
            <div className="mr-2 flex flex-col justify-end items-end">
              <div className="text-sm">
                {data && data.email && data.email.displayName}
              </div>
              <div className="text-xs">
                {data && data.email && data.email.emailId}
              </div>
            </div>
            {data && data.email && (
              <img
                src={data.email.picture}
                alt={"profile"}
                className="w-10 h-10 bg-gray-200 rounded-full"
              />
            )}
            {/* <div className="w-10 h-10 bg-gray-200 rounded-full" /> */}
          </div>
        </div>
      </div>
      <div className="">
        <Outlet />
      </div>
    </div>
  );
}

export default EmailIdLayout;
