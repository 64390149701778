import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_BASEURL } from "../../constants/apis";
import LoadingComp from "../../components/LoadingComp";
import ErrorComp from "../../components/ErrorComp";
import UsersCard from "../../components/ama/UsersCard";

function AmaHome() {
  const [tabValue] = useState(0);

  const { data, error, loading } = useFetch(
    `${API_BASEURL}/ama/v2/account/users/emailaccount`
  );

  if (loading) {
    return <LoadingComp />;
  } else if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <>
      <div className="font-semibold text-lg ml-5 my-5">User accounts</div>

      <div className="px-2">
        {data.emails
          .filter((each) =>
            tabValue === 1 ? each.authBy === "ms" : each.authBy === "google"
          )
          .map((da) => (
            <UsersCard data={da} key={da._id} />
          ))}
      </div>
    </>
  );
}

export default AmaHome;
