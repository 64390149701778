import { useCallback, useRef } from "react";
import ReactFlow, {
  Controls,
  Background,
  Panel,
  addEdge,
  useEdgesState,
  useNodesState,
  updateEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import FollowupNode from "../components/builder/followupNode";
import ReminderNode from "../components/builder/reminderNode";
import MessageNode from "../components/builder/messageNode";
import NotifiationNode from "../components/builder/notificationNode";
import ZeroNode from "../components/builder/zeroNode";
import axios from "axios";
import { API_BASEURL, MESSAGEID, TOKEN_KEY } from "../constants/apis";

const initialNodes = [
  {
    id: "0",

    data: { label: "Email Not replied", subtitle: "Fast followup" },
    position: { x: 0, y: 100 },
    type: "zeroNode",
  },
  {
    id: "1",

    data: {
      label: "Follow up",
      input: "Notified next week ",
      note: "Write note as if needed",
    },
    position: { x: 0, y: 300 },
    type: "followupNode",
  },
];

const nodeTypes = {
  followupNode: FollowupNode,
  reminderNode: ReminderNode,
  messageNode: MessageNode,
  notificationNode: NotifiationNode,
  zeroNode: ZeroNode,
};

const initialEdges = [
  {
    id: "0->1",
    source: "0",
    target: "1",
  },
];

let id = 2;
const getId = () => `${id++}`;
const defaultViewPort = { x: 0, y: 0, zoom: 2 };
function FastBuilder() {
  const edgeUpdateSuccessful = useRef(true);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = useCallback(
    (params) => setEdges((els) => addEdge(params, els)),
    []
  );

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const onNodeDelete = useCallback((deleted) => {
    console.log("ON delete", deleted);
    const isContainIntial = deleted.filter((item) => {
      console.log("items", item);
      return item.id === "0";
    });
    setNodes(deleted);
    console.log("ON check", isContainIntial);
  }, []);

  const addNewNode = (actionName = "Node", type = "default") => {
    const id = getId();
    const typeParams = ["default", "output", "input", "group"];
    let maxX = 1,
      maxY = 0;
    nodes.forEach((node) => {
      if (node.position.x > maxX) maxX = node.position.x;
      if (node.position.y > maxY) maxY = node.position.y;
    });

    const newNode = {
      id,
      position: {
        x: maxX,
        y: maxY + 100,
      },
      data: { label: `${id}` },
      type,
    };

    setNodes((nds) => nds.concat(newNode));
  };
  const postFollowUp = async (e) => {
    e.preventDefault();

    let followupTime = new Date();
    await axios
      .post(
        `${API_BASEURL}/api/v2/followup/setbuilder`,
        {
          notify_at: followupTime,
          email_id: localStorage.getItem(MESSAGEID),
          type: "fast",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
          },
        }
      )
      .then((res) => {})
      .catch((e) => {
        // setError(e.response.data.error);
      })
      .finally(() => {
        window.location.href = "/";
      });
  };

  return (
    <div style={{ height: "100vh ", width: "100vw" }} className="bg-white">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnect={onConnect}
        onNodesDelete={onNodeDelete}
        defaultViewport={defaultViewPort}
        nodeTypes={nodeTypes}
        nodeOrigin={[0.2, 0]}
        attributionPosition="top-right"
        fitView
      >
        <Background color="#000" gap={16} />
        <Controls />
        <Panel position="top-center">
          <div className="bg-white border shadow-lg rounded-md flex flex-col w-full py-2 px-4 ">
            <div className="text-center text-xl font-bold  text-white bg-blue-500">
              Fast Followup Template
            </div>
            <div className=" flex w-full ">
              <button
                onClick={() => addNewNode("follow up", "followupNode")}
                className="hover:bg-violet-200 hover:text-violet-800 rounded-md px-2 mx-2 py-1 duration-500 text-xs"
              >
                Follow Up
              </button>
              <button
                onClick={() => addNewNode("message", "messageNode")}
                className="hover:bg-violet-200 hover:text-violet-800 rounded-md px-2 mx-2 py-1 duration-500 text-xs"
              >
                Add message
              </button>
              <button
                onClick={() => addNewNode("reminder", "reminderNode")}
                className="hover:bg-violet-200 hover:text-violet-800 rounded-md px-2 mx-2 py-1 duration-500 text-xs"
              >
                Add reminder
              </button>
              <button
                onClick={() => addNewNode("notification", "notificationNode")}
                className="hover:bg-violet-200 hover:text-violet-800 rounded-md px-2 mx-2 py-1 duration-500 text-xs"
              >
                Show notification
              </button>
            </div>
          </div>
        </Panel>
        <Panel position="bottom-right">
          <div className="bg-white border shadow-lg rounded-md flex w-full py-2 px-4 ">
            <button
              onClick={postFollowUp}
              className="hover:bg-violet-200 bg-purple-500 hover:text-violet-800 text-white rounded-md px-3 mx-2 py-1 duration-500"
            >
              Save
            </button>
            <a
              href="/builder"
              className="hover:bg-violet-200 border border-purple-500 hover:text-violet-800 rounded-md px-3 mx-2 py-1 duration-500"
            >
              Cancel
            </a>
          </div>
        </Panel>
      </ReactFlow>
    </div>
  );
}

export default FastBuilder;
