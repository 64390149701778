import React, { useCallback, useState } from "react";
import { Handle, Position } from "reactflow";

function NotifiationNode({ data }) {
  const [textData, setTextData] = useState("");
  const onChange = useCallback((evt) => {
    setTextData(evt.target.value);
  });
  const isValidConnection2 = useCallback((evt) => {
    console.log("Full Object NotificationNode", evt);

    if (evt.sourceHandle !== "f") {
      return false;
    } else {
      return true;
    }
  }, []);
  return (
    <div className="bg-white border shadow-md rounded-md p-3">
      <Handle
        type="target"
        position={Position.Right}
        isValidConnection={isValidConnection2}
        id={`f`}
        className=" p-3 bg-green-500 mb-5 rounded-md "
      />
      <div className="flex flex-col text-sm mt-3">
        <label htmlFor="text">Notification note:</label>

        <input
          id="text"
          name="text"
          value={textData}
          onChange={onChange}
          placeholder="Note"
          className="border focus:outline-none px-2 py-1.5 rounded-md"
        />
        <button className="rounded-md shadow-sm focus:shadow-md border-blue-500 text-blue-700 py-1.5">
          Save
        </button>
      </div>
    </div>
  );
}

export default NotifiationNode;
