import React, { useState } from "react";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import SourceIcon from "@mui/icons-material/Source";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL, TOKEN_KEY } from "../../../constants/apis";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";
import axios from "axios";

function AddFolder() {
  const folders = [{ id: 1, displayName: "Test one folder" }];
  const [posting, setPosting] = useState(false);
  const [selected, setSelected] = useState([]);

  const { emailId } = useParams();

  const { data, loading, error } = useFetch(
    `${API_BASEURL}/api/v2/folder/remote/${emailId}`
  );

  const naviagte = useNavigate();

  const gunwanted = [
    "CHAT",
    "SENT",
    "TRASH",
    "DRAFT",
    "SPAM",
    "CATEGORY_FORUMS",
    "CATEGORY_UPDATES",
    "CATEGORY_PERSONAL",
    "CATEGORY_PROMOTIONS",
    "CATEGORY_SOCIAL",
    "STARRED",
    "UNREAD",
    "[Imap]/Drafts",
  ];

  const postFolder = () => {
    setPosting(true);
    let postFormData = new FormData();

    for (var i = 0; i < selected.length; i++) {
      postFormData.append("folders", selected[i]);
    }

    postFormData.append("email_id", data.email._id);

    axios
      .post(
        `${API_BASEURL}/api/v2/folder/share`,

        postFormData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      )
      .then((response) => {
        naviagte("/shared", { replace: true });
        setPosting(false);
      });
  };

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }
  return (
    <div className="px-6">
      <div className="px-5 py-2 text-center font-semibold ">
        Please Select the <span className="font-bold ">FOLDER </span> that We
        should help you
      </div>
      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <nav aria-label="main mailbox folders">
          <List>
            {data &&
              data.folders &&
              data.folders.map((fold, index) => (
                <div
                  key={fold.id}
                  className={
                    gunwanted.includes(fold.id)
                      ? "hidden"
                      : "block w-full items-center my-3 mx-auto"
                  }
                >
                  <ListItem disablePadding key={index}>
                    <ListItemButton
                      onClick={() => {
                        if (selected.includes(fold.id)) {
                          setSelected(selected.filter((fo) => fo !== fold.id));
                        } else {
                          setSelected((selected) => [...selected, fold.id]);
                        }
                      }}
                      disabled={posting}
                      selected={selected.includes(fold.id) ? true : false}
                    >
                      <ListItemIcon>
                        {selected.includes(fold.id) ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <SourceIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={fold.name} />
                    </ListItemButton>
                  </ListItem>
                </div>
              ))}
          </List>
        </nav>
        <Divider />
      </Box>

      <div className="mt-4 justify-items-end items-end">
        <Button
          disableElevation
          variant="contained"
          disabled={(selected.length === 0 ? true : false) || posting}
          onClick={() => {
            postFolder();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default AddFolder;
