import React from "react";
import BackButton from "../../../components/BackButton";
import { IconButton } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

function CompanySearch() {
  const list = ["name", "na", "uf", "fkuweh"];
  return (
    <div>
      <BackButton />
      <div className="text-xl text-center justify-center flex w-full font-bold">
        COMPANIES TO CONNECT
      </div>
      {/*  <br />
      <div className="flex w-full justify-center px-10">
        <input
          type="text"
          className="bg-gray-300/30 rounded-md py-3 px-2 flex w-full outline-0 focus:ring-1 text-sm font-normal"
          placeholder="Search companies..."
        />
      </div> */}
      <br />
      <div className="ml-5 font-bold text-blue-500">Suggested companies</div>
      {list.map((each) => (
        <div
          keay={each}
          className="flex w-full items-center justify-between px-5 hover:bg-gray-300/30 py-3 cursor-pointer duration-300"
        >
          <div className="flex w-full items-center">
            <div className=" w-16 h-16 rounded-full bg-gray-500" />
            <div className=" ml-4">
              <div>Starbucks</div>
              <div className="text-sm ">Advertising & Marketing</div>
              <div className="text-xs ">
                Short description about the company
              </div>
            </div>
          </div>
          <IconButton>
            <AddCircle />
          </IconButton>
        </div>
      ))}
    </div>
  );
}

export default CompanySearch;
