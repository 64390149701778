import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import axios from "axios";
import { API_BASEURL } from "../constants/apis";

function SignUp() {
  const naviagte = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [values, setValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const { email, password, firstName, lastName, confirmPassword } = values;

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(`${API_BASEURL}/api/v2/auth/signup `, {
        firstName,
        lastName,
        email,
        password,
      })
      .then((res) => {
        naviagte("/login");
      })
      .catch((e) => {
        console.log(e);
        if (e.response.message) {
          setError(e.response.message);
        } else if (e.message) {
          setError(e.message);
        } else {
          setError("Unknown error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BackButton />
      <div className="relative items-center px-6 lg:px-8  ">
        <div className="pt-12 min-h-max ">
          <div>
            <div className="text-center">
              <div className="flex justify-center mb-4">
                <img src={`../assets/icon-80.png`} alt="Ignite Spring" />
              </div>
              <h1 className="text-3xl font-bold  text-gray-900 sm:text-2xl">
                Register
              </h1>

              {error && <p className="text-red-500">{error}</p>}

              <form onSubmit={handleSubmit}>
                <div className="flex flex-col">
                  <div className="grid grid-cols-2">
                    <input
                      className=" bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
                      placeholder="First name"
                      type="text"
                      value={firstName}
                      onChange={handleOnChange}
                      name="firstName"
                      disabled={loading}
                      required
                    />
                    <input
                      className=" bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
                      placeholder="Last name"
                      type="text"
                      value={lastName}
                      onChange={handleOnChange}
                      disabled={loading}
                      name="lastName"
                    />
                  </div>
                  <input
                    className=" bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
                    placeholder="email"
                    onChange={handleOnChange}
                    value={email}
                    name="email"
                    type="email"
                    disabled={loading}
                    required
                  />
                  <input
                    className=" bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
                    placeholder="password"
                    type="password"
                    onChange={handleOnChange}
                    value={password}
                    name="password"
                    disabled={loading}
                    required
                  />
                  <input
                    className=" bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
                    placeholder="Confirm password"
                    type="password"
                    onChange={handleOnChange}
                    value={confirmPassword}
                    name="confirmPassword"
                    disabled={loading}
                    required
                  />

                  <button
                    type="submit"
                    disabled={loading}
                    className={`${
                      loading ? "bg-gray-500" : "bg-blue-600"
                    } py-2 text-white rounded-sm w-full mt-3`}
                  >
                    Sign up
                  </button>
                </div>
              </form>
              <div className="flex text-sm">
                Already have an account ?
                <button
                  onClick={() => naviagte("/login")}
                  className="text-blue-500   "
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
