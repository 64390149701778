import { BrowserRouter } from "react-router-dom";

import ScrollToTop from "./services/ScrollToTop";

import RoutesList from "./routes/RoutesList";
import { AlertProvider } from "./components/context/AlertContext";
import LoadingComp from "./components/LoadingComp";

function App({ isOfficeInitialized }) {
  if (!isOfficeInitialized) {
    return (
      <div>
        <LoadingComp />
      </div>
    );
  }

  return (
    <div onContextMenu={(e) => console.log()}>
      <AlertProvider>
        <BrowserRouter>
          <ScrollToTop>
            <RoutesList />
          </ScrollToTop>
        </BrowserRouter>
      </AlertProvider>
    </div>
  );
}

export default App;
