import React from "react";
import EmailCard from "../../../components/EmailCard";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL } from "../../../constants/apis";
import GetMatchButton from "../../../components/GetMatchButton";
import DealMailCard from "../../../components/DealMailCard";

function Deals() {
  const { emailId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useFetch(
    `${API_BASEURL}/api/v2/deal/emails/${emailId}`
  );

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }

  if (data && data.emails.length < 1) {
    return (
      <div className="h-[70vh] flex justify-center items-center">
        <div className="flex justify-center items-center h-full">
          No Deals mail currently
        </div>
        <GetMatchButton />
      </div>
    );
  }

  return (
    <div>
      {data.emails.map((each) => (
        <DealMailCard data={each} key={each._id} />
      ))}
      <GetMatchButton />
    </div>
  );
}

export default Deals;
