import React, { Fragment, useContext, useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { ChevronCircleLeft12Regular } from "@fluentui/react-icons";
import { AddAlarm, Bookmark, Handshake, MoreVert } from "@mui/icons-material";
import { Menu, Transition } from "@headlessui/react";
import { API_BASEURL, TOKEN_KEY } from "../constants/apis";
import axios from "axios";
import DotLoadingComp from "./DotLoadingComp";
import AlertContext from "./context/AlertContext";

function EmailMarkOption({ data }) {
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState({
    selectedDateTime: "",
  });
  const { fetchAlertData } = useContext(AlertContext);
  useEffect(() => {
    setSelected("");
    setPostData({
      selectedDateTime: "",
    });
  }, []);

  const postDeal = async () => {
    setLoading(true);
    await axios
      .get(
        `${API_BASEURL}/api/v2/deal/mark/${data._id}`,

        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
          },
        }
      )
      .then((res) => {
        data.isDeal = true;
      })
      .catch((e) => {
        // setError(e.response.data.error);
      })
      .finally(() => {
        setLoading(false);
        fetchAlertData();
      });
  };

  const removeDeal = async () => {
    setLoading(true);
    await axios
      .get(
        `${API_BASEURL}/api/v2/deal/remove/${data._id}`,

        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
          },
        }
      )
      .then((res) => {
        data.isDeal = false;
      })
      .catch((e) => {
        // setError(e.response.data.error);
      })
      .finally(() => {
        setLoading(false);
        fetchAlertData();
      });
  };

  const postFollowUp = async (e, value) => {
    e.preventDefault();
    setLoading(true);

    let followupTime = value ? value : postData.selectedDateTime;
    await axios
      .post(
        `${API_BASEURL}/api/v2/followup/set`,
        {
          notify_at: followupTime,
          email_id: data._id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
          },
        }
      )
      .then((res) => {
        data.followUpScheduled = true;
        setSelected("");
      })
      .catch((e) => {
        // setError(e.response.data.error);
      })
      .finally(() => {
        setPostData({
          selectedDateTime: "",
        });
        setLoading(false);
        fetchAlertData();
      });
  };

  const removeFolloUp = async () => {
    setLoading(true);
    await axios
      .patch(
        `${API_BASEURL}/api/v2/followup/remove`,
        {
          followup: "mail",
          email_id: data._id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
          },
        }
      )
      .then((res) => {
        data.followUpScheduled = false;
        setSelected("");
      })
      .catch((e) => {
        // setError(e.response.data.error);
      })
      .finally(() => {
        setLoading(false);
        fetchAlertData();
      });
  };

  return (
    <div className="font-normal text-md">
      <Menu>
        <Menu.Button>
          <Tooltip enterDelay={700} title="More options">
            <IconButton>
              <MoreVert />
            </IconButton>
          </Tooltip>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transition opacity-0 scale-95"
          enterTo="transition opacity-100 scale-100 "
          leave="transition ease-in duration-200"
          leaveTo="transition opacity-0 scale-95"
          leaveFrom="transition opacity-100 scale-100 "
        >
          <Menu.Items className="absolute left-0  ml-20 w-56 z-50 origin-top-left overflow-hidden bg-white  text-black devide-x  shadow-lg rounded-lg border border-gray-300">
            {loading ? (
              <div className="bg-gray-300 flex justify-center w-full py-5">
                <DotLoadingComp />
              </div>
            ) : selected === "followup" ? (
              <div className="flex flex-col">
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelected("");
                    }}
                    className="flex py-2 px-2 hover:bg-blue-200 duration-200"
                  >
                    <ChevronCircleLeft12Regular className="w-15 h-15" />
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      let now = new Date();

                      now.setTime(now.getTime() + 24 * 60 * 60 * 1000);

                      setPostData({
                        ...postData,
                        selectedDateTime: now,
                      });
                      e.preventDefault();

                      postFollowUp(e, now);
                    }}
                    className="flex py-2 px-2 hover:bg-blue-200 duration-200"
                  >
                    <Bookmark /> tomorrow
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      let now = new Date();

                      now.setTime(now.getTime() + 3 * 24 * 60 * 60 * 1000);

                      setPostData({
                        ...postData,
                        selectedDateTime: now,
                      });
                      e.preventDefault();

                      postFollowUp(e, now);
                    }}
                    className="flex py-2 px-2 hover:bg-blue-200 duration-200"
                  >
                    <Bookmark /> 3 day
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      let now = new Date();

                      now.setTime(now.getTime() + 7 * 24 * 60 * 60 * 1000);

                      setPostData({
                        ...postData,
                        selectedDateTime: now,
                      });
                      e.preventDefault();

                      postFollowUp(e, now);
                    }}
                    className="flex py-2 px-2 hover:bg-blue-200 duration-200"
                  >
                    <Bookmark /> week
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelected("Datepick");
                    }}
                    className="flex py-2 px-2 hover:bg-blue-200 duration-200"
                  >
                    <Bookmark /> custome
                  </button>
                </Menu.Item>
              </div>
            ) : selected === "Datepick" ? (
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <Menu.Item>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setSelected("");
                      }}
                      className="flex py-2 px-2 hover:bg-blue-200 duration-200"
                    >
                      <ChevronCircleLeft12Regular className="w-15 h-15" />
                    </button>
                  </Menu.Item>

                  <div className="flex">
                    <input
                      type="datetime-local"
                      min={new Date(Date.now() - 15 * 60 * 1000)}
                      onChange={(e) => {
                        e.preventDefault();
                        setPostData({
                          ...postData,
                          selectedDateTime: e.target.value,
                        });
                      }}
                      value={postData.selectedDateTime}
                      className="flex py-2 px-2 hover:bg-blue-200 duration-200"
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        postFollowUp(e);
                      }}
                      disabled={postData.selectedDateTime === ""}
                      className={`${
                        postData.selectedDateTime === ""
                          ? "bg-gray-200 text-gray-600"
                          : "bg-blue-500 hover:bg-blue-200 "
                      } "flex py-2 px-2 duration-200 text-center "`}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col">
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      data.followUpScheduled
                        ? removeFolloUp()
                        : setSelected("followup");
                    }}
                    className={` ${
                      data.followUpScheduled ? "text-red-500" : ""
                    } flex py-2 px-2 items-center hover:bg-blue-200 duration-200`}
                  >
                    <Bookmark />
                    {data.followUpScheduled ? "remove reminding" : "follow up"}
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem("messageId", data._id);
                      window.location.href = "/builder";
                    }}
                    className={` ${
                      data.followUpScheduled ? "text-red-500" : ""
                    } flex py-2 px-2 items-center hover:bg-blue-200 duration-200`}
                  >
                    <Bookmark />
                    Customized followup
                  </button>
                </Menu.Item>
                {data.followUpScheduled ? (
                  <></>
                ) : (
                  <Menu.Item>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        data.followUpScheduled
                          ? removeFolloUp()
                          : setSelected("followup");
                      }}
                      className="flex py-2 px-2 items-center hover:bg-blue-200 duration-200"
                    >
                      <AddAlarm /> reminder
                    </button>
                  </Menu.Item>
                )}
                <Menu.Item>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      data.isDeal ? removeDeal() : postDeal();
                    }}
                    className={`${
                      data.isDeal ? "text-red-500" : ""
                    } flex py-2 px-2 items-center hover:bg-blue-200 duration-200`}
                  >
                    <Handshake /> {data.isDeal ? "not deal" : "deal"}
                  </button>
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default EmailMarkOption;
