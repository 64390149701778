import { Outlet, Navigate } from "react-router-dom";
import { TOKEN_KEY } from "../constants/apis";

const useAuth = () => {
  const user = localStorage.getItem(TOKEN_KEY);
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoute = () => {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
