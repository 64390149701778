import React, { useContext, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import AlertContext from "../../../components/context/AlertContext";
import LoadingComp from "../../../components/LoadingComp";

function Alerts() {
  const { emailId } = useParams();
  const { alertLoading, fetchAlertDetail, detailAlert, userRole } =
    useContext(AlertContext);

  useEffect(() => {
    fetchAlertDetail(emailId);
  }, []);

  return (
    <div>
      {alertLoading && <LoadingComp />}
      {!alertLoading && detailAlert ? (
        detailAlert.map((eachAlert) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              if (eachAlert.associatedId) {
                window.location.href = `${window.location.href}/followup/${eachAlert.associatedId}`;
              }
            }}
            className="py-2 border-b border-opacity-30  hover:bg-slate-50/40 duration-300"
          >
            <div
              key={eachAlert._id}
              className={`px-3  flex justify-between my-2 cursor-pointer ${
                (userRole === "user" &&
                  eachAlert.read_status.includes("UNREAD")) ||
                (userRole === "ama" &&
                  eachAlert.read_status.includes("VAUNREAD"))
                  ? " font-bold"
                  : ""
              }`}
            >
              <div>
                <div className="text-sm">{eachAlert.title} </div>
                <div className="text-xs">{eachAlert.message} </div>
              </div>
              <div className="text-xs flex flex-col items-end">
                <div> {moment(eachAlert.notification_time).fromNow()}</div>
                <div
                  className={
                    (userRole === "user" &&
                      eachAlert.read_status.includes("UNREAD")) ||
                    (userRole === "ama" &&
                      eachAlert.read_status.includes("VAUNREAD"))
                      ? " bg-yellow-500 h-2 w-2 rounded-full"
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
      {!alertLoading && (!detailAlert || detailAlert.length < 1) ? (
        <div className="min-h-[70vh]  flex w-full h-full justify-center items-center ">
          <div classname="text-center mt-10">No notification</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Alerts;
