import React from "react";
import { Outlet } from "react-router-dom";
import BackButton from "../../components/BackButton";

function AmaEmailIdLayout() {
  return (
    <div className="">
      <Outlet />
    </div>
  );
}

export default AmaEmailIdLayout;
