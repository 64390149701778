import React, { useEffect, useState } from "react";
import ResponsesCard from "../../../components/ResponseCard";
import CreateResponseButton from "../../../components/CreateResponseButton";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL } from "../../../constants/apis";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { Send20Regular } from "@fluentui/react-icons";
import DotLoadingComp from "../../../components/DotLoadingComp";
import EmailCard from "../../../components/EmailCard";
import axios from "axios";

function ComposeFollowup() {
  const { email_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [thread, setThread] = useState();
  const [sending, setSending] = useState(false);
  const [draft, setDraft] = useState();

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${API_BASEURL}/api/v2/email/message/${email_id}/user`)
      .then((res) => {
        setResponse(res.data.email);
        console.log(res.data);
        setThread(res.data.thread);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [email_id]);

  const { data, error } = useFetch(`${API_BASEURL}/api/v2/draft/${email_id}`);

  const sendRes = () => {
    setSending(true);
    let postFormData = new FormData();

    postFormData.append("draft", draft);
    postFormData.append("emailMessageId", email_id);
    postFormData.append("subject", `Follow-Up: ${response.subject}`);
    // pushmail && postFormData.append("pushmailId", pushmail._id);

    axios
      .post(`${API_BASEURL}/api/v2/compose/reply`, postFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("secret")}`,
        },
      })
      .then((res) => {
        // Office.context.ui.messageParent(JSON.stringify({ status: "success" }));
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        window.location.href = "/";
        setSending(false);
      });
  };

  if (loading) {
    return <LoadingComp />;
  }
  if (error) {
    return <ErrorComp error={error} />;
  }
  return (
    <div>
      Write Followup mail
      {/* {data &&
        data.drafts &&
        data.drafts.map((each) => <ResponsesCard data={each} key={each._id} />)} */}
      <div className="mb-10">
        <EmailCard data={response} options={false} />
      </div>
      <OutlookCompose />
    </div>
  );
  function OutlookCompose() {
    return (
      <div>
        <div className="flex mx-10">
          <button
            onClick={() => {
              sendRes();
            }}
            disabled={sending}
            className={` duration-300  flex  items-center justify-center text-gray-500  text-md w-16 h-20  ${
              sending
                ? "bg-gray-300"
                : " border-gray-500 border hover:bg-blue-800 hover:border-blue-400 focus:bg-blue-900"
            }`}
          >
            {sending ? (
              <DotLoadingComp />
            ) : (
              <div className="flex flex-col items-center justify-center py-5 px-5 ">
                <Send20Regular />

                <div className="text-xs mt-3">Send</div>
              </div>
            )}
          </button>
          <div>
            <div className=" mx-5 ">
              <div>
                <div className="flex text-sm  px-4  items-center ">
                  <div className=" border border-gray-800  w-20 text-center py-1.5 text-xs">
                    From
                  </div>
                  {/* {sectedEmailAccount?.emailId} */}
                  <div className="ml-4">Current user </div>
                </div>

                <div className="flex text-sm  px-4  items-center ">
                  <div className=" border border-gray-800  w-20 text-center py-1.5 ">
                    To
                  </div>
                  <div className="ml-4 ">
                    {exreactEmail(response?.fromName)}
                  </div>
                </div>

                <div className="flex text-sm  px-4  items-center ">
                  <div className=" text-gray-400 w-20 text-center py-1.5 text-xs">
                    Subject
                  </div>
                  <div className=""> Folow-Up: {response?.subject}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b flex w-full h-2"></div>
        <div className="mb-16">
          <ReactQuill
            placeholder="Compose your message..."
            theme="bubble"
            value={draft}
            readOnly={sending}
            modules={{ toolbar: [] }}
            onChange={async (e) => {
              setDraft(e);
            }}
            style={{
              ".qlContainer": {
                color: "red",
                backgroundColor: "yellow",
              },
              ".qlEditor": {
                color: "red",
                backgroundColor: "yellow",
              },
            }}
          />
        </div>
      </div>
    );
  }

  function exreactEmail(text) {
    const regex = /<([^>]+)>/;
    const match = text?.match(regex);

    return match ? match[1] : null;
  }
}

export default ComposeFollowup;
