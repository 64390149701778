import React, { useState, Fragment } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {
  ArrowReplyAll24Regular,
  Delete24Filled,
  ArrowClockwise20Filled,
} from "@fluentui/react-icons";
import Axios from "axios";
import { MarkEmailRead, Wysiwyg } from "@mui/icons-material";
import sanitizeHtml from "sanitize-html";
import moment from "moment";
import { Transition } from "@headlessui/react";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { API_BASEURL, ROLE_KEY, WEB_URL } from "../constants/apis";
import { officeDialogHandler } from "../utils/officeDialog";
/* global  Office */

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

function ResponsesCard({ data }) {
  const [expand, setExpand] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState([]);
  const [value, setValue] = useState();
  const [comment, setComment] = useState("");
  const [isShowing, setIsShowing] = useState(false);

  // let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 500);

  const deleteRes = (draftId) => {
    setDeleting(true);

    Axios.delete(`${API_BASEURL}/ama/v2/draft/${draftId}`)
      .then((res) => {
        setDeleted([...deleted, data]);
        window.location.reload();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const sendFeedback = async () => {
    let postFormData = new FormData();
    // postFormData.append("email", props.each.email._id);
    // postFormData.append("response", props.each._id);
    // postFormData.append("rate", value);
    // postFormData.append("comment", comment);

    Axios.post(`${API_BASEURL}/comment`, postFormData)
      .then((res) => {
        setIsShowing(!isShowing);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div>
      <div className="flex flex-col items-center ">
        <div className={`w-[20rem] ${!expand ? "h-[10rem]" : ""} relative `}>
          <Transition
            as={Fragment}
            show={!isShowing}
            enter="transform transition duration-[200ms]"
            enterFrom="opacity-0  scale-0"
            enterTo="opacity-100 rotate-0 scale-100"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100"
            leaveTo="opacity-0  scale-0 "
          >
            <div
              className={`  flex items-center justify-center
                deleted.includes(props.each._id) ? "hidden" : "visible"
              `}
            >
              <div className="w-[20rem]  mt-2  mx-auto">
                <div className="border-gray-300/50 border group     rounded-md  hover:shadow-lg  duration-300 ">
                  <div
                    onClick={() => {
                      setExpand(!expand);
                    }}
                    className="w-[20rem]  hover:bg-zinc-300/10 duration-300 cursor-pointer py-2 px-3"
                  >
                    <div>
                      <div className="flex  ">
                        <div>
                          <div className="line-clamp-1 text-md">
                            {data.emailMessageId.emailAccountId.displayName}
                          </div>
                          <div
                            className="line-clamp-1 text-sm"
                            color="text.secondary"
                          >
                            {data.emailMessageId.fromName}
                          </div>
                        </div>
                        <div className="flex flex-col items-center ml-auto">
                          {data.labels?.includes("EDITED") && (
                            <div className="flex items-center ml-auto">
                              <p className="text-[11px] ">edited</p>

                              <MarkEmailRead
                                fontSize="sm"
                                sx={{
                                  color: true ? "#16AD10" : "#c4c4c4",
                                }}
                              />
                            </div>
                          )}
                          <div className="text-center text-[10px]">
                            {`${moment(data.createdAt).format(
                              "MMM DD/YY hh:mm a"
                            )}`}
                          </div>
                        </div>
                      </div>
                      <div className="text-xs ">
                        <div className={!expand ? "line-clamp-1" : ""}>
                          {sanitizeHtml(data.message, {
                            allowedTags: [],
                            allowedAttributes: {},
                            allowedIframeHostnames: [],
                          })}
                          {/* Message */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex p-2">
                    <div className="mr-auto flex items-center">
                      {/* <IconButton
                        onClick={() => {
                     
                        }}
                        aria-label="add to favorites"
                      >
                        <Edit24Regular />
                      </IconButton> */}
                      {localStorage.getItem(ROLE_KEY) === "ama" && (
                        <IconButton aria-label="share">
                          {deleting ? (
                            <ArrowClockwise20Filled className="animate-spin" />
                          ) : (
                            <Delete24Filled
                              onClick={() => {
                                deleteRes(data._id);
                              }}
                            />
                          )}
                        </IconButton>
                      )}
                    </div>
                    <div
                      // onClick={(e) => {
                      //   setIsShowing(!isShowing);
                      // }}
                      className="flex items-center opacity-30 hover:opacity-100"
                    >
                      {/* <ThumbsUpDownRounded /> */}
                      <StyledRating
                        name="highlight-selected-only"
                        // defaultValue={2}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                          setIsShowing(!isShowing);
                        }}
                        value={value}
                        IconContainerComponent={IconContainer}
                        getLabelText={(value) => customIcons[value].label}
                        highlightSelectedOnly
                      />
                    </div>
                    <div className="ml-2">
                      {localStorage.getItem(ROLE_KEY) === "user" && (
                        <Button
                          disableElevation
                          elevation={0}
                          variant="contained"
                          onClick={() => {
                            officeDialogHandler(
                              `${WEB_URL}/compose?reply=true&draftId=${data._id}&emailMessageId=${data.emailMessageId._id}&type=reply`
                            );
                          }}
                          sx={{
                            boxShadow: 0,
                          }}
                          startIcon={<Wysiwyg />}
                        >
                          Full view
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
}

export default ResponsesCard;

// <Transition
// as={Fragment}
// show={isShowing}
// enter="transform transition duration-200"
// enterFrom="opacity-0  scale-0"
// enterTo="opacity-100 rotate-0 scale-100"
// leave="transform duration-[400ms] transition ease-in-out"
// leaveFrom="opacity-100 rotate-0 scale-100"
// leaveTo="opacity-0  scale-0 "
// >
// <div
//   className={`  flex items-center justify-center
//     deleted.includes(props.each._id) ? "hidden" : "visible"
//   `}
// >
//   <div className="w-[20rem]  mt-6  mx-auto">
//     <div className="border-gray-500/50 border group   rounded-sm  bg-white hover:shadow-lg hover:shadow-zinc-200 duration-300 ">
//       <div className="w-[20rem]  bg-white duration-300 cursor-pointer py-2 px-3">
//         <div className="line-clamp-1 text-md">
//           <StyledRating
//             name="highlight-selected-only"
//             // defaultValue={2}
//             onChange={(event, newValue) => {
//               setValue(newValue);
//             }}
//             value={value}
//             IconContainerComponent={IconContainer}
//             getLabelText={(value) => customIcons[value].label}
//             highlightSelectedOnly
//           />
//         </div>
//         <textarea
//           placeholder="write your feedback"
//           className="w-full ring-none outline-none"
//           rows={2}
//           value={comment}
//           onChange={(e) => {
//             setComment(e.target.value);
//           }}
//         />
//       </div>
//       <div className="bg-zinc-200 flex p-2 w-full justify-end">
//         {window.localStorage.getItem("isVa") === "true" ? (
//           <div className="flex ">
//             <button
//               onClick={() => {
//                 sendFeedback();
//               }}
//               className="bg-blue-600 px-3 py-1  rounded-md text-white  "
//             >
//               Submit
//             </button>
//           </div>
//         ) : (
//           <div></div>
//         )}
//       </div>
//     </div>
//   </div>
// </div>
// </Transition>
