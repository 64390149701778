import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROLE_KEY } from "../constants/apis";
import { ChevronRight, Source, SourceOutlined } from "@mui/icons-material";

function LabelsCard({ data }) {
  const navigate = useNavigate();
  const { emailId } = useParams();
  return (
    <div
      className="flex w-full justify-between items-center border-b border-gray-500/20 cursor-pointer hover:shadow-lg hover:bg-white/10 px-7 py-4 hover:rounded-md duration-300 "
      onClick={() =>
        navigate(
          `/${localStorage.getItem(ROLE_KEY)}/emailid/${emailId}/emails/${
            data._id
          }`
        )
      }
    >
      <div className="flex">
        <div>
          <SourceOutlined />
        </div>

        <div className="flex flex-col justify-start ml-5">
          <div className="text-lg font-medium">{data?.displayName}</div>
          <div className="text-xs text-gray-400">{data?.folderId}</div>
        </div>
      </div>
      <div className="flex">
        <ChevronRight />
      </div>
    </div>
  );
}

export default LabelsCard;
