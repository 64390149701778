import React, { useCallback, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import sanitizeHtml from "sanitize-html";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AILogo from "../../assets/aiLogo.svg";
import { API_BASEURL } from "../../constants/apis";

function MessageNode({ data }) {
  const [textData, setTextData] = useState("");
  const [isAIGenerationEnabled, setIsAIGenerationEnabled] = useState(false);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    if (textData.startsWith(" ")) {
      setIsAIGenerationEnabled(true);
    } else {
      setIsAIGenerationEnabled(false);
    }
  }, [textData]);

  const onChange = useCallback((evt) => {
    setTextData(evt.target.value);
  }, []);

  const generateByAi = () => {
    let postFormData = new FormData();

    postFormData.append("prompt", textData);

    fetch(`${API_BASEURL}/ama/v2/openai/generate/followup`, {
      method: "POST",
      body: postFormData,
    })
      .then((respo) => {
        const decoder = new TextDecoder("utf-8");
        const reader = respo.body.getReader();

        const stream = new ReadableStream({
          start(controller) {
            function push() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }

                const chunkValue = decoder.decode(value);

                setResponse((prevText) => {
                  const cleanedText = sanitizeHtml(
                    prevText.replace(/(\r\n|\r|\n)/g, "<br />"),
                    {
                      allowedTags: ["br"],
                      allowedAttributes: {},
                      allowedIframeHostnames: [],
                    }
                  );

                  return cleanedText + chunkValue;
                });

                controller.enqueue(value);

                return push();
              });
            }
            push();
          },
        });

        return new Response(stream, {
          headers: { "Content-Type": "text/html" },
        }).text();
      })
      .catch((err) => {
        const { response } = err;
        setError(response.data.error.message);
        setGenerating(false);
      });
  };
  const isValidConnection2 = useCallback((evt) => {
    if (evt.sourceHandle !== "d") {
      return false;
    } else {
      return true;
    }
  }, []);
  const isValidConnection3 = useCallback((evt) => {
    console.log("Full Object MessageNode 3", evt);

    if (evt.sourceHandle !== "f") {
      return false;
    } else {
      return true;
    }
  }, []);
  return (
    <div className="bg-white border shadow-md rounded-md ">
      <Handle
        type="target"
        position={Position.Top}
        isValidConnection={isValidConnection2}
        id={`c`}
        className="text-red-500 p-3 bg-purple-500 mb-5 rounded-md"
      />
      <Handle
        type="target"
        position={Position.Left}
        id={`e`}
        isValidConnection={isValidConnection3}
        className=" p-3 bg-white border-2 border-green-500 mb-5 rounded-md "
      />
      <div className="flex flex-col text-sm p-3 pl-3">
        <label htmlFor="text" className="my-3">
          Sample message:
        </label>
        {isAIGenerationEnabled ? (
          response ? (
            <ReactQuill value={response} onChange={setResponse} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
      <div className={"flex flex-col  bg-gray-50 px-5 py-1.5"}>
        {isAIGenerationEnabled ? (
          <div className="flex  flex-col">
            <input
              id="prompt"
              name="prompt"
              onChange={onChange}
              value={textData}
              className={
                " border shadow-md focus:outline-none px-2 py-1.5 rounded-md mt-2"
              }
              placeholder="Press 'space' for AI"
            />
            {response ? (
              <button className="rounded-md shadow-sm focus:shadow-md  text-blue-700 border border-blue-500 hover:bg-blue-200 py-1.5 mt-3">
                Save
              </button>
            ) : (
              ""
            )}
            <button
              onClick={generateByAi}
              className="flex justify-center rounded-md shadow-sm focus:shadow-md bg-violet-500 text-white border border-violet-500 hover:bg-purple-700 py-1.5 mt-3 duration-300"
            >
              <span>
                <img src={AILogo} alt="ms" className="w-8 pr-4  grayscale" />
              </span>
              Generate
            </button>
          </div>
        ) : (
          <div className="flex flex-col">
            <input
              id="text"
              name="text"
              value={textData}
              onChange={onChange}
              className={"border focus:outline-none px-2 py-1.5 rounded-md"}
              placeholder="Press 'space' for AI"
            />
            <button className="rounded-md shadow-sm focus:shadow-md  text-blue-700 border border-blue-500 hover:bg-blue-200 py-1.5 mt-3">
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageNode;
