import React, { useContext, useEffect } from "react";
import GLogo from "../../assets/Glogo.svg";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import useFetch from "../../hooks/useFetch";
import { API_BASEURL } from "../../constants/apis";
import LoadingComp from "../../components/LoadingComp";
import ErrorComp from "../../components/ErrorComp";
import { EmailOutlined, ManageAccounts } from "@mui/icons-material";
import UsersCard from "../../components/ama/UsersCard";

function UserHome() {
  const naviagte = useNavigate();

  const { data, loading, error } = useFetch(API_BASEURL + "/api/v2/account");

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }
  return (
    <div>
      {/* AccountName */}
      <div className="flex w-full justify-between border-b sticky top-0 bg-white  pb-2  z-50">
        <BackButton />
        <div
          onClick={() => naviagte("/profile")}
          className="flex w-full justify-end hover:cursor-pointer"
        >
          <div className="flex flex-col items-end mr-2">
            <div className="text-sm"> {data?.user?.firstName} </div>
            <div className="text-xs"> {data?.user?.email} </div>
          </div>
          <div className=" w-8 h-8 mr-5 text-white flex justify-center items-center text-center bg-gray-300 rounded-full">
            {data?.user?.firstName?.substring(0, 1)}
          </div>
        </div>
      </div>
      {/* Title */}
      <div className="flex w-full justify-between  mt-5 px-5 mb-5">
        <div className=" justify-center font-semibold">
          <span>
            <ManageAccounts />
          </span>
          Email Accounts
        </div>
      </div>
      {data &&
        data.emails &&
        data.emails.map((each) => <UsersCard data={each} key={each._id} />)}

      {data && data.emails && data.emails.length < 1 && (
        <div className="w-full justify-center items-center flex flex-col pt-10">
          <div> You haven't share any email account to Ignite Spring </div>
          <div>
            <img
              src="https://static.thenounproject.com/png/1291634-200.png"
              alt="arrow"
              className="animate-bounce w-16 h-16"
            />
          </div>
        </div>
      )}
      <button
        onClick={() => naviagte("/user/addmail")}
        className="mt-10 text-white rounded-md border border-blue-700 py-3 w-full bg-blue-600 hover:bg-blue-500 cursor-pointer duration-300 hover:shadow-md"
      >
        Add Account
      </button>
    </div>
  );
}

export default UserHome;
