import React, { useState } from "react";
import moment from "moment";
import { IconButton } from "@mui/material";
import { ExpandLess } from "@mui/icons-material";

function EmailViewComp({ each, isView = false }) {
  const [open, setOpen] = useState(isView);

  function exreactEmail(text) {
    const regex = /<([^>]+)>/;
    const match = text.match(regex);

    return match ? match[1] : null;
  }

  if (open) {
    return (
      <div
        className={`mx-5 mt-3 pb-2 border rounded shadow-sm group duration-300`}
      >
        <div className=" border-b bg-zinc-300/10 mb-4 py-4 px-3">
          <div className="font-medium  ">
            {each?.fromName.match(/^(.*?)\s+<\w+@\w+\.\w+>$/) &&
              each?.fromName.match(/^(.*?)\s+<\w+@\w+\.\w+>$/)[1]}
          </div>
          <div className="font-medium text-xs">
            {exreactEmail(each?.fromAddress)}
          </div>
          <div className="flex text-[13px]  ">
            <div className="">To : {each?.toRecipientsName[0]}</div>
          </div>

          <div className=" text-zinc-500 text-[12px]  ">
            {`${moment(`${each?.sentDateTime}`).format(
              "DD MMM/YY | hh:mm:ss a"
            )}`}
          </div>
        </div>

        <div className="px-3 ">
          {each &&
            (each.bodyType === "text/html" ? (
              <div className="px-2 text-sm text-black overflow-x-auto ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: each.bodyContent,
                  }}
                />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: each.bodyContent.replace(/\n/g, "<br />"),
                }}
              />
              // <div>{parse(response.bodyContent)}</div>
            ))}
        </div>
        <div className="flex w-full justify-end">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
          >
            <ExpandLess />
          </IconButton>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`cursor-pointer hover:shadow-md  mx-5 my-3 py-2 border rounded shadow-sm group duration-300`}
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <div className="flex w-full justify-between  px-2 mb-2">
          <div>
            <div className="font-medium text-sm ">
              {each?.fromName.match(/^(.*?)\s+<\w+@\w+\.\w+>$/) &&
                each?.fromName.match(/^(.*?)\s+<\w+@\w+\.\w+>$/)[1]}
            </div>
            <div className="font-medium text-xs">
              {exreactEmail(each?.fromAddress)}
            </div>
          </div>
          <div className=" text-zinc-500 text-[12px]  ">
            {`${moment(`${each?.sentDateTime}`).format(
              "DD MMM/YY | hh:mm:ss a"
            )}`}
          </div>
        </div>

        <div className={`text-zinc-500 line-clamp-2 text-xs px-2`}>
          {each?.bodyPreview}
        </div>
      </div>
    );
  }
}

export default EmailViewComp;
