import React from "react";
import { Outlet } from "react-router-dom";
import LogoutButton from "../../components/LogoutButton";

function HomeLayout() {
  return (
    <div>
      <div className="min-h-screen">
        <Outlet />
      </div>
      <LogoutButton />
    </div>
  );
}

export default HomeLayout;
