import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import axios from "axios";
import { API_BASEURL, TOKEN_KEY, ROLE_KEY } from "../constants/apis";

function Signin() {
  const naviagte = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [values, setValues] = useState({ email: "", password: "" });
  const { email, password } = values;

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(`${API_BASEURL}/api/v2/auth/signin`, {
        email,
        password,
      })
      .then((res) => {
        localStorage.setItem(TOKEN_KEY, res.data.token);
        localStorage.setItem(ROLE_KEY, res.data.role);
        if (res.data.role === "user") {
          naviagte("/user");
        } else if (res.data.role === "ama") {
          naviagte("/ama");
        }
      })
      .catch((e) => {
        setError(e.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <BackButton />
      <div className="pt-12 min-h-max  px-6 ">
        <div>
          <div className="text-center">
            <div className="flex justify-center mb-2">
              <img src={`../assets/icon-80.png`} alt="Ignite Spring" />
            </div>
            <h1 className="text-3xl font-bold  text-gray-900 sm:text-2xl mb-5">
              SignIn
            </h1>

            {error && <p className="text-red-500">{error}</p>}

            <form onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <input
                  className="  border-gray-300 focus:border-blue-300  outline-none ring-gray-300 focus:ring-blue-600 ring-1 focus:ring-2 rounded-sm py-3 px-3 my-2 "
                  placeholder="Email"
                  onChange={handleOnChange}
                  value={email}
                  name="email"
                  type="email"
                  disabled={loading}
                  required
                />
                <input
                  className="  border-gray-300 focus:border-blue-300  outline-none ring-gray-300 focus:ring-blue-600 ring-1 focus:ring-2 rounded-sm py-3 px-3 my-2 "
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={handleOnChange}
                  value={password}
                  disabled={loading}
                  required
                />

                <button
                  type="submit"
                  disabled={loading}
                  className={`${
                    loading
                      ? "bg-gray-200"
                      : "border-blue-700  bg-blue-600 hover:bg-blue-700 hover:shadow-md "
                  }  text-white rounded-sm py-3  px-8 my-3  duration-300 `}
                >
                  {loading ? "..." : "Sign in"}
                </button>
              </div>
            </form>
            <div className="flex text-sm">
              Don't have an account ?
              <button
                onClick={() => naviagte("/signup")}
                className="text-blue-500   "
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
