import React, { useCallback } from "react";
import { Handle, Position } from "reactflow";

function ZeroNode({ data }) {
  const isValidConnection = useCallback((evt) => {
    console.log("Full Object ZeroNode", evt);

    if (evt.sourceHandle !== "a") {
      return false;
    } else {
      return true;
    }
  }, []);

  return (
    <div className="bg-white border border-black shadow-md rounded-md p-3 text-sm">
      <div className="px-5 ">{data.label}</div>
      <div className="text-xs text-blue-500 mb-3 px-5 font-bold ">
        {data.subtitle}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        isValidConnection={isValidConnection}
        className="inset-y-16 bg-white p-2 rounded-md  border-red-500 border-2"
      />
    </div>
  );
}

export default ZeroNode;
