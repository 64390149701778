import React, { useEffect, useState } from "react";
import Axios from "axios";

import { useParams } from "react-router-dom";

import moment from "moment";
import LoadingComp from "../components/LoadingComp";
import { API_BASEURL } from "../constants/apis";
import BackButton from "../components/BackButton";
import EmailViewComp from "../components/EmailViewComp";

function EmailView() {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [thread, setThread] = useState();

  const { id, role } = useParams();

  useEffect(() => {
    setLoading(true);

    Axios.get(`${API_BASEURL}/api/v2/email/message/${id}/${role}`)
      .then((res) => {
        setResponse(res.data.email);

        setThread(res.data.thread);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [id]);

  if (loading) {
    return <LoadingComp />;
  }
  return (
    <div className="">
      <div className="sticky top-0 bg-white flex w-full items-center">
        <BackButton />
        {thread?.messages[thread?.messages.length - 1]?.subject}
      </div>
      {/* <div className="mx-5 py-2 border rounded shadow-md  ">
        <div className="flex justify-between">
          <div className="flex items-center">
            {!loading && response && (
              <div className="font-bold line-clamp-1 ml-2">
                {response.subject}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="flex w-full justify-end">
            <div className="text-center text-zinc-500 text-[12px]">
              {`${moment(`${response?.sentDateTime}`).format(
                "DD MMM/YY | hh:mm:ss a"
              )}`}
            </div>
          </div>
          <div className="flex border-b bg-zinc-300/10 mb-4 py-4 px-3">
            <div>
              <div className="line-clamp-1">{response?.fromName}</div>
              <div className="flex text-[13px]  ">
                <div className="line-clamp-1">
                  To : {response?.toRecipientsName[0]}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 ">
            {response &&
              (response.bodyType === "text/html" ? (
                <div className="px-6 mx-4 text-sm text-black">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: response.bodyContent,
                    }}
                  />
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: response.bodyContent.replace(/\n/g, "<br />"),
                  }}
                  //
                />
                // <div>{parse(response.bodyContent)}</div>
              ))}
          </div>
        </div>
      </div> */}
      {thread?.messages?.map((each, index) => (
        <EmailViewComp each={each} isView={index === 0} key={each._id} />
      ))}
      {/* <div>
        <div className="mt-4 flex">
          <div
            onClick={() => {
              // console.log(message);
              setResponseView(!responseview);
              // naviagte(`/va/res/${response._id}`, { replace: true });
              // Office.context.ui.messageParent(
              //   JSON.stringify({ message: "create", messageId: response._id })
              // );
            }}
            onMouseEnter={() => setHoverFab(true)}
            onMouseLeave={() => setHoverFab(false)}
            className="cursor-pointer  bg-blue-600 hover:bg-blue-700 focus:bg-blue-800 text-white hover:rounded-md rounded-full hover:px-4 px-2 py-2 fixed bottom-8 right-5  shadow-lg"
          >
            {hoverFab ? (
              <div> Responses </div>
            ) : (
              <div>
                <Add />
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default EmailView;
