import { Outlet, Navigate } from "react-router-dom";
import { ROLE_KEY } from "../constants/apis";

const useAuth = () => {
  const user = localStorage.getItem(ROLE_KEY);
  if (user) {
    return user;
  } else {
    return false;
  }
};

const UnprotectedRoute = () => {
  const auth = useAuth();

  return auth ? <Navigate replace={true} to={`/${auth}`} /> : <Outlet />;
};

export default UnprotectedRoute;
