import React from "react";
import { API_BASEURL, TOKEN_KEY } from "../../constants/apis";
import MSLogo from "../../assets/msLogo.svg";
import GLogo from "../../assets/Glogo.svg";
import BackButton from "../../components/BackButton";
import { officeDialogHandler } from "../../utils/officeDialog";
/* global Office */

function AddMailAddress() {
  // var loginDialog;

  // const googlelogInHandler = async () => {
  //   await Office.context.ui.displayDialogAsync(
  //     ``,
  //     { height: 80, width: 50 },
  //     (result) => {
  //       if (result.status === Office.AsyncResultStatus.Failed) {
  //         console.log("Error from dialog");
  //       } else {
  //         loginDialog = result.value;
  //         loginDialog = result.value;

  //         loginDialog.addEventHandler(
  //           Office.EventType.DialogMessageReceived,
  //           processLoginMessage
  //         );
  //         loginDialog.addEventHandler(
  //           Office.EventType.DialogEventReceived,
  //           processLoginDialogEvent
  //         );
  //       }
  //     }
  //   );

  //   const processLoginMessage = (arg) => {
  //     let messageFromDialog = JSON.parse(arg.message);
  //     if (messageFromDialog.status === "success") {
  //       // We now have a valid access tokenn.

  //       localStorage.setItem("secret", messageFromDialog.result);
  //       localStorage.setItem("service", messageFromDialog.auth);
  //       window.location.reload();
  //       // window.localStorage.setItem("secret", messageFromDialog.result);
  //       // naviagte("/home");
  //       loginDialog.close();
  //     } else {
  //       // Something went wrong with authentication or the authorization of the web application.
  //       loginDialog.close();
  //       console.log(
  //         "Something went wrong with authentication or the authorizatio"
  //       );
  //     }
  //     // loginDialog.close();
  //   };
  //   const processLoginDialogEvent = (arg) => {
  //     processDialogEvent(arg);
  //   };
  // };

  const processDialogEvent = (arg) => {
    switch (arg.error) {
      case 12002:
        console.log(
          "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
        );
        break;
      case 12003:
        console.log(
          "The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required."
        );
        break;
      case 12006:
        // 12006 means that the user closed the dialog instead of waiting for it to close.
        // It is not known if the user completed the login or logout, so assume the user is
        // logged out and revert to the app's starting state. It does no harm for a user to
        // press the login button again even if the user is logged in.
        console.log("Note logedin");
        console.log({ authStatus: "notLoggedIn", headerMessage: "Welcome" });
        break;
      default:
        console.log("Unknown error in dialog box.");
        break;
    }
  };
  return (
    <>
      <BackButton />
      <div className="relative items-center px-6 lg:px-8  ">
        <div className="pt-12 min-h-max ">
          <div>
            <div className="text-center">
              <p className="mt-2 text-sm text-gray-600">
                Simplify your inbox and take control of your day with our smart
                Outlook virtual assistant
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <button
                  // onClick={() => logInHandler()}
                  className="rounded-sm bg-none border items-center w-60 justify-center bg-white  duration-200  border-gray-300  px-4 py-3 flex font-semibold   "
                >
                  <span>
                    <img
                      src={MSLogo}
                      alt="ms"
                      className="w-8 pr-4  grayscale"
                    />
                  </span>
                  Continue with Microsoft
                </button>
              </div>
              <div className="mt-4 flex items-center justify-center gap-x-6">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    // window.location.href = `${API_BASEURL}/api/v2/account/google/auth?token=${localStorage.getItem(
                    //   TOKEN_KEY
                    // )}`;

                    officeDialogHandler(
                      `${API_BASEURL}/api/v2/account/google/auth?token=${localStorage.getItem(
                        TOKEN_KEY
                      )}`
                    );
                  }}
                  className="rounded-sm bg-none border w-60 text-white items-center  bg-blue-500 hover:bg-blue-600 hover:shadow-lg duration-200 focus:bg-blue-700 border-blue-500  pr-4  flex font-semibold  shadow-sm "
                >
                  <span>
                    <img
                      src={GLogo}
                      alt="ms"
                      className="w-15 mr-3 rounded-l-sm bg-white "
                    />
                  </span>
                  Continue with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMailAddress;
