import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

function CreateResponseButton() {
  const [hoverFab, setHoverFab] = useState(false);
  // const [responseview, setResponseView] = useState(false);
  const navigate = useNavigate();

  const { emailId, email_id } = useParams();

  return (
    <div>
      <div className="mt-4 flex">
        <div
          onClick={() => {
            navigate(`/ama/emailid/${emailId}/create-response/${email_id}`);
          }}
          onMouseEnter={() => setHoverFab(true)}
          onMouseLeave={() => setHoverFab(false)}
          className="cursor-pointer  bg-blue-600 hover:bg-blue-700 focus:bg-blue-800 text-white rounded-md   px-4 py-2 fixed bottom-8 right-5  shadow-lg duration-300"
        >
          <div> CREATE RESPONSE </div>
        </div>
      </div>
    </div>
  );
}

export default CreateResponseButton;
