import React from "react";

function LogoutButton() {
  const signOutHandler = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/");
  };

  return (
    <div>
      <div className="1 m-1 mt-6 rounded-md text-sm  flex justify-end text-red-600  ">
        <button
          className="border rounded-sm border-red-500 px-2 py-1 hover:bg-red-50 duration-300 focus:bg-red-100"
          onClick={() => signOutHandler()}
        >
          LOGOUT
        </button>
      </div>
    </div>
  );
}

export default LogoutButton;
