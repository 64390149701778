import React, { useCallback, useState } from "react";
import { Handle, Position } from "reactflow";

function ReminderNode({ data }) {
  const [textData, setTextData] = useState("");
  const onChange = useCallback((evt) => {
    setTextData(evt.target.value);
  });
  const isValidConnection = useCallback((evt) => {
    if (evt.sourceHandle !== "a") {
      return false;
    } else {
      return true;
    }
  }, []);
  const isValidConnection2 = useCallback((evt) => {
    if (evt.sourceHandle !== "d") {
      return false;
    } else {
      return true;
    }
  }, []);

  return (
    <div className="bg-white border shadow-md rounded-md p-3">
      <Handle
        type="target"
        position={Position.Top}
        isValidConnection={isValidConnection}
        id="b"
        className="bg-sky-500 p-2 rounded-md"
      />
      <div className="flex flex-col text-sm mb-5">
        <label htmlFor="text">Mark for reminder:</label>
        {textData}
        <input
          id="text"
          name="text"
          type="datetime-local"
          onChange={onChange}
          className="border focus:outline-none px-2 py-1.5 rounded-md"
        />

        <input
          id="text"
          name="text"
          onChange={onChange}
          placeholder="Note"
          className="border focus:outline-none px-2 py-1.5 rounded-md mt-2"
        />
        <button className="rounded-md shadow-sm focus:shadow-md border-blue-500 text-white bg-blue-700 py-1.5 mt-2">
          Save
        </button>
      </div>
      {/* <Handle type="source" position={Position.Bottom} id="a" /> */}
      <Handle
        type="source"
        position={Position.Bottom}
        isValidConnection={isValidConnection2}
        id="d"
        className="bg-white rounded-md border-purple-500 p-2 border-2"
      />
    </div>
  );
}

export default ReminderNode;
