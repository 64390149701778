import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import SourceIcon from "@mui/icons-material/SourceOutlined";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL, ROLE_KEY } from "../../../constants/apis";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";

import SyncButton from "../../../components/syncButton";
import {
  ChevronRight,
  Grid3x3,
  ViewAgenda,
  ViewDay,
  ViewModule,
} from "@mui/icons-material";
import SharedMails from "./SharedMails";
import LabelsCard from "../../../components/LabelsCard";

function SharedFolder() {
  const { emailId } = useParams();
  const navigate = useNavigate();
  const [mailView, setMailView] = useState(false);

  const { data, loading, error } = useFetch(
    `${API_BASEURL}/api/v2/folder/${emailId}`
  );

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }

  if (data && data.folders.length < 1) {
    return <div>It seems like the user doesn't share any label </div>;
  }

  return (
    <div className="min-h-screen">
      <div className="flex w-full justify-end ">
        <Tooltip enterDelay={700} title="Sync mail click once ">
          <SyncButton />
        </Tooltip>
        <Tooltip enterDelay={700} title="Switch View ">
          <button
            className="flex hover:bg-gray-500 rounded-md px-2 py-2 space-x-2 duration-300 text-sm items-center"
            onClick={() => setMailView(!mailView)}
          >
            {mailView ? <ViewModule /> : <ViewDay />}
            {mailView ? "Label view" : "Mail view"}
          </button>
        </Tooltip>
      </div>
      {mailView ? (
        <SharedMails />
      ) : (
        <nav aria-label="main mailbox folders" className=" py-3 px-4 ">
          {data &&
            data.folders &&
            data.folders.map((fold, index) => (
              <LabelsCard data={fold} key={fold.id} />
            ))}
        </nav>
      )}
    </div>
  );
}

export default SharedFolder;
