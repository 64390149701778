import React from "react";
import Logo from "../assets/icon-80.png";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

function Landing() {
  const navigate = useNavigate();

  return (
    <div className="relative items-center px-6 lg:px-8  ">
      <div className="pt-12 min-h-max ">
        <div className="text-center">
          <div className="flex justify-center mb-4  ">
            <img src={Logo} alt="Ignite Spring" className="animate-spin-low" />
          </div>
          <h1 className="text-3xl font-black  text-gray-900 sm:text-2xl">
            Wellcome
          </h1>
          <div className="flex w-full justify-center">
            <h1 className=" font-medium w-[55vw] text-gray-900 ">
              Ignite Your Inbox with Precision AI-powered tools
            </h1>
          </div>

          <div className="mt-10 flex flex-col items-center justify-center gap-x-6">
            <button
              onClick={() => navigate("/login")}
              className="text-white rounded-md border border-blue-700 py-2 px-8 bg-blue-600 hover:bg-blue-500 cursor-pointer duration-300 hover:shadow-md items-center"
            >
              GET STARTED
              <span>
                <ChevronRight />
              </span>
            </button>
          </div>
          {/* <button
            onClick={() => navigate("/builder")}
            className="rounded-md border border-green-700 py-1.5 px-8 mt-10  cursor-pointer duration-300 hover:shadow-md items-center"
          >
            Builder test
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Landing;
